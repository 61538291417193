import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-e-hinman',
  templateUrl: './e-hinman.component.html',
  styleUrls: ['./e-hinman.component.css']
})
export class EHinmanComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}