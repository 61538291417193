import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-publishing',
  templateUrl: './publishing.component.html',
  styleUrls: ['./publishing.component.css']
})
export class PublishingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}